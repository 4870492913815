import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _5b3e85dc = () => interopDefault(import('../pages/about-us.vue' /* webpackChunkName: "pages/about-us" */))
const _af171d12 = () => interopDefault(import('../pages/account-settings.vue' /* webpackChunkName: "pages/account-settings" */))
const _071e7cbc = () => interopDefault(import('../pages/actions/index.vue' /* webpackChunkName: "pages/actions/index" */))
const _b147816a = () => interopDefault(import('../pages/admin.vue' /* webpackChunkName: "pages/admin" */))
const _2ab60e6c = () => interopDefault(import('../pages/admind/index.vue' /* webpackChunkName: "pages/admind/index" */))
const _293cc1d6 = () => interopDefault(import('../pages/apple.vue' /* webpackChunkName: "pages/apple" */))
const _96dc6854 = () => interopDefault(import('../pages/blog.vue' /* webpackChunkName: "pages/blog" */))
const _f6ad3956 = () => interopDefault(import('../pages/building-owners.vue' /* webpackChunkName: "pages/building-owners" */))
const _1de2ac27 = () => interopDefault(import('../pages/challenges/index.vue' /* webpackChunkName: "pages/challenges/index" */))
const _44e61606 = () => interopDefault(import('../pages/cities.vue' /* webpackChunkName: "pages/cities" */))
const _c8c23452 = () => interopDefault(import('../pages/companies.vue' /* webpackChunkName: "pages/companies" */))
const _01f6c546 = () => interopDefault(import('../pages/earthday.vue' /* webpackChunkName: "pages/earthday" */))
const _7d823fa1 = () => interopDefault(import('../pages/ecogroups.vue' /* webpackChunkName: "pages/ecogroups" */))
const _1e63af1e = () => interopDefault(import('../pages/explore-challenges.vue' /* webpackChunkName: "pages/explore-challenges" */))
const _11d7520c = () => interopDefault(import('../pages/facebook.vue' /* webpackChunkName: "pages/facebook" */))
const _1244bed1 = () => interopDefault(import('../pages/faqs.vue' /* webpackChunkName: "pages/faqs" */))
const _60fb981e = () => interopDefault(import('../pages/how-it-works.vue' /* webpackChunkName: "pages/how-it-works" */))
const _3f948e65 = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _000e46be = () => interopDefault(import('../pages/logout.vue' /* webpackChunkName: "pages/logout" */))
const _26937547 = () => interopDefault(import('../pages/my-meters.vue' /* webpackChunkName: "pages/my-meters" */))
const _5ae9c518 = () => interopDefault(import('../pages/my-usage.vue' /* webpackChunkName: "pages/my-usage" */))
const _e15e7b8c = () => interopDefault(import('../pages/new_sign_up.vue' /* webpackChunkName: "pages/new_sign_up" */))
const _1d0366a4 = () => interopDefault(import('../pages/old_actions/index.vue' /* webpackChunkName: "pages/old_actions/index" */))
const _105acd82 = () => interopDefault(import('../pages/old_challenges/index.vue' /* webpackChunkName: "pages/old_challenges/index" */))
const _50a38594 = () => interopDefault(import('../pages/old_index.vue' /* webpackChunkName: "pages/old_index" */))
const _bd543f66 = () => interopDefault(import('../pages/old_login.vue' /* webpackChunkName: "pages/old_login" */))
const _ab57f758 = () => interopDefault(import('../pages/old_syncdata/index.vue' /* webpackChunkName: "pages/old_syncdata/index" */))
const _0c846b5d = () => interopDefault(import('../pages/old-account-settings.vue' /* webpackChunkName: "pages/old-account-settings" */))
const _1cce5d5a = () => interopDefault(import('../pages/old-my-usage.vue' /* webpackChunkName: "pages/old-my-usage" */))
const _86294d50 = () => interopDefault(import('../pages/organizers.vue' /* webpackChunkName: "pages/organizers" */))
const _34700634 = () => interopDefault(import('../pages/pilot.vue' /* webpackChunkName: "pages/pilot" */))
const _0bc1c17c = () => interopDefault(import('../pages/pricing.vue' /* webpackChunkName: "pages/pricing" */))
const _cc7aac6a = () => interopDefault(import('../pages/privacy-policy.vue' /* webpackChunkName: "pages/privacy-policy" */))
const _41117782 = () => interopDefault(import('../pages/reset_password.vue' /* webpackChunkName: "pages/reset_password" */))
const _2c9b81d7 = () => interopDefault(import('../pages/settings.vue' /* webpackChunkName: "pages/settings" */))
const _563108cf = () => interopDefault(import('../pages/signup/index.vue' /* webpackChunkName: "pages/signup/index" */))
const _039bb13c = () => interopDefault(import('../pages/syncdata/index.vue' /* webpackChunkName: "pages/syncdata/index" */))
const _488b92f2 = () => interopDefault(import('../pages/terms-conditions.vue' /* webpackChunkName: "pages/terms-conditions" */))
const _4dfcc413 = () => interopDefault(import('../pages/admind/background_tasks.vue' /* webpackChunkName: "pages/admind/background_tasks" */))
const _1c31eb6e = () => interopDefault(import('../pages/admind/challenges/index.vue' /* webpackChunkName: "pages/admind/challenges/index" */))
const _df5e4d86 = () => interopDefault(import('../pages/admind/organizations/index.vue' /* webpackChunkName: "pages/admind/organizations/index" */))
const _8736e1e6 = () => interopDefault(import('../pages/admind/pelm_energy_accounts/index.vue' /* webpackChunkName: "pages/admind/pelm_energy_accounts/index" */))
const _61caec46 = () => interopDefault(import('../pages/admind/pelm_subscriptions/index.vue' /* webpackChunkName: "pages/admind/pelm_subscriptions/index" */))
const _2d3a9624 = () => interopDefault(import('../pages/admind/property/index.vue' /* webpackChunkName: "pages/admind/property/index" */))
const _3b8f27d3 = () => interopDefault(import('../pages/admind/teams/index.vue' /* webpackChunkName: "pages/admind/teams/index" */))
const _5fe024b6 = () => interopDefault(import('../pages/admind/users/index.vue' /* webpackChunkName: "pages/admind/users/index" */))
const _cb06c528 = () => interopDefault(import('../pages/admind/utility_providers/index.vue' /* webpackChunkName: "pages/admind/utility_providers/index" */))
const _73b1b912 = () => interopDefault(import('../pages/challenges/create.vue' /* webpackChunkName: "pages/challenges/create" */))
const _46a67fad = () => interopDefault(import('../pages/old_account-settings/account.vue' /* webpackChunkName: "pages/old_account-settings/account" */))
const _49c3cd09 = () => interopDefault(import('../pages/old_account-settings/meter.vue' /* webpackChunkName: "pages/old_account-settings/meter" */))
const _5a0f15f1 = () => interopDefault(import('../pages/old_account-settings/meter_old.vue' /* webpackChunkName: "pages/old_account-settings/meter_old" */))
const _1769f230 = () => interopDefault(import('../pages/old_account-settings/notifications.vue' /* webpackChunkName: "pages/old_account-settings/notifications" */))
const _2d0d160d = () => interopDefault(import('../pages/old_account-settings/team.vue' /* webpackChunkName: "pages/old_account-settings/team" */))
const _19d66128 = () => interopDefault(import('../pages/old_account-settings/utility.vue' /* webpackChunkName: "pages/old_account-settings/utility" */))
const _2a34d95f = () => interopDefault(import('../pages/old_challenges/create.vue' /* webpackChunkName: "pages/old_challenges/create" */))
const _dd0bcb06 = () => interopDefault(import('../pages/signup/confirm.vue' /* webpackChunkName: "pages/signup/confirm" */))
const _57001a4c = () => interopDefault(import('../pages/signup/confirm_email.vue' /* webpackChunkName: "pages/signup/confirm_email" */))
const _d4949b92 = () => interopDefault(import('../pages/signup/old_index.vue' /* webpackChunkName: "pages/signup/old_index" */))
const _6348b1bd = () => interopDefault(import('../pages/signup/send-verification.vue' /* webpackChunkName: "pages/signup/send-verification" */))
const _bd02cdbe = () => interopDefault(import('../pages/admind/organizations/create.vue' /* webpackChunkName: "pages/admind/organizations/create" */))
const _d4e89d10 = () => interopDefault(import('../pages/admind/challenges/_challengeId.vue' /* webpackChunkName: "pages/admind/challenges/_challengeId" */))
const _c08b3c54 = () => interopDefault(import('../pages/admind/pelm_energy_accounts/_energyAccountId.vue' /* webpackChunkName: "pages/admind/pelm_energy_accounts/_energyAccountId" */))
const _5788632b = () => interopDefault(import('../pages/admind/pelm_subscriptions/_subscriptionId.vue' /* webpackChunkName: "pages/admind/pelm_subscriptions/_subscriptionId" */))
const _40b01e0b = () => interopDefault(import('../pages/admind/property/_propertyId.vue' /* webpackChunkName: "pages/admind/property/_propertyId" */))
const _58fe93b8 = () => interopDefault(import('../pages/admind/teams/_teamId.vue' /* webpackChunkName: "pages/admind/teams/_teamId" */))
const _ef1ee810 = () => interopDefault(import('../pages/admind/users/_userId.vue' /* webpackChunkName: "pages/admind/users/_userId" */))
const _60d424f6 = () => interopDefault(import('../pages/admind/utility_providers/_utilId.vue' /* webpackChunkName: "pages/admind/utility_providers/_utilId" */))
const _78fcac50 = () => interopDefault(import('../pages/challenges/rules/_rule.vue' /* webpackChunkName: "pages/challenges/rules/_rule" */))
const _3af170ab = () => interopDefault(import('../pages/admind/organizations/_organizationId/details.vue' /* webpackChunkName: "pages/admind/organizations/_organizationId/details" */))
const _049f43f0 = () => interopDefault(import('../pages/admind/organizations/_organizationId/update.vue' /* webpackChunkName: "pages/admind/organizations/_organizationId/update" */))
const _3839540d = () => interopDefault(import('../pages/actions/_tipName.vue' /* webpackChunkName: "pages/actions/_tipName" */))
const _3b5fc842 = () => interopDefault(import('../pages/challenges/_challenge.vue' /* webpackChunkName: "pages/challenges/_challenge" */))
const _12294125 = () => interopDefault(import('../pages/old_actions/_tipName.vue' /* webpackChunkName: "pages/old_actions/_tipName" */))
const _43d57c8e = () => interopDefault(import('../pages/old_syncdata/_subscriptionId.vue' /* webpackChunkName: "pages/old_syncdata/_subscriptionId" */))
const _21f7eca1 = () => interopDefault(import('../pages/syncdata/_subscriptionId.vue' /* webpackChunkName: "pages/syncdata/_subscriptionId" */))
const _d9fad944 = () => interopDefault(import('../pages/old_challenges/_challenge/_challenge_slug.vue' /* webpackChunkName: "pages/old_challenges/_challenge/_challenge_slug" */))
const _75eceb4e = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/about-us",
    component: _5b3e85dc,
    name: "about-us"
  }, {
    path: "/account-settings",
    component: _af171d12,
    name: "account-settings"
  }, {
    path: "/actions",
    component: _071e7cbc,
    name: "actions"
  }, {
    path: "/admin",
    component: _b147816a,
    name: "admin"
  }, {
    path: "/admind",
    component: _2ab60e6c,
    name: "admind"
  }, {
    path: "/apple",
    component: _293cc1d6,
    name: "apple"
  }, {
    path: "/blog",
    component: _96dc6854,
    name: "blog"
  }, {
    path: "/building-owners",
    component: _f6ad3956,
    name: "building-owners"
  }, {
    path: "/challenges",
    component: _1de2ac27,
    name: "challenges"
  }, {
    path: "/cities",
    component: _44e61606,
    name: "cities"
  }, {
    path: "/companies",
    component: _c8c23452,
    name: "companies"
  }, {
    path: "/earthday",
    component: _01f6c546,
    name: "earthday"
  }, {
    path: "/ecogroups",
    component: _7d823fa1,
    name: "ecogroups"
  }, {
    path: "/explore-challenges",
    component: _1e63af1e,
    name: "explore-challenges"
  }, {
    path: "/facebook",
    component: _11d7520c,
    name: "facebook"
  }, {
    path: "/faqs",
    component: _1244bed1,
    name: "faqs"
  }, {
    path: "/how-it-works",
    component: _60fb981e,
    name: "how-it-works"
  }, {
    path: "/login",
    component: _3f948e65,
    name: "login"
  }, {
    path: "/logout",
    component: _000e46be,
    name: "logout"
  }, {
    path: "/my-meters",
    component: _26937547,
    name: "my-meters"
  }, {
    path: "/my-usage",
    component: _5ae9c518,
    name: "my-usage"
  }, {
    path: "/new_sign_up",
    component: _e15e7b8c,
    name: "new_sign_up"
  }, {
    path: "/old_actions",
    component: _1d0366a4,
    name: "old_actions"
  }, {
    path: "/old_challenges",
    component: _105acd82,
    name: "old_challenges"
  }, {
    path: "/old_index",
    component: _50a38594,
    name: "old_index"
  }, {
    path: "/old_login",
    component: _bd543f66,
    name: "old_login"
  }, {
    path: "/old_syncdata",
    component: _ab57f758,
    name: "old_syncdata"
  }, {
    path: "/old-account-settings",
    component: _0c846b5d,
    name: "old-account-settings"
  }, {
    path: "/old-my-usage",
    component: _1cce5d5a,
    name: "old-my-usage"
  }, {
    path: "/organizers",
    component: _86294d50,
    name: "organizers"
  }, {
    path: "/pilot",
    component: _34700634,
    name: "pilot"
  }, {
    path: "/pricing",
    component: _0bc1c17c,
    name: "pricing"
  }, {
    path: "/privacy-policy",
    component: _cc7aac6a,
    name: "privacy-policy"
  }, {
    path: "/reset_password",
    component: _41117782,
    name: "reset_password"
  }, {
    path: "/settings",
    component: _2c9b81d7,
    name: "settings"
  }, {
    path: "/signup",
    component: _563108cf,
    name: "signup"
  }, {
    path: "/syncdata",
    component: _039bb13c,
    name: "syncdata"
  }, {
    path: "/terms-conditions",
    component: _488b92f2,
    name: "terms-conditions"
  }, {
    path: "/admind/background_tasks",
    component: _4dfcc413,
    name: "admind-background_tasks"
  }, {
    path: "/admind/challenges",
    component: _1c31eb6e,
    name: "admind-challenges"
  }, {
    path: "/admind/organizations",
    component: _df5e4d86,
    name: "admind-organizations"
  }, {
    path: "/admind/pelm_energy_accounts",
    component: _8736e1e6,
    name: "admind-pelm_energy_accounts"
  }, {
    path: "/admind/pelm_subscriptions",
    component: _61caec46,
    name: "admind-pelm_subscriptions"
  }, {
    path: "/admind/property",
    component: _2d3a9624,
    name: "admind-property"
  }, {
    path: "/admind/teams",
    component: _3b8f27d3,
    name: "admind-teams"
  }, {
    path: "/admind/users",
    component: _5fe024b6,
    name: "admind-users"
  }, {
    path: "/admind/utility_providers",
    component: _cb06c528,
    name: "admind-utility_providers"
  }, {
    path: "/challenges/create",
    component: _73b1b912,
    name: "challenges-create"
  }, {
    path: "/old_account-settings/account",
    component: _46a67fad,
    name: "old_account-settings-account"
  }, {
    path: "/old_account-settings/meter",
    component: _49c3cd09,
    name: "old_account-settings-meter"
  }, {
    path: "/old_account-settings/meter_old",
    component: _5a0f15f1,
    name: "old_account-settings-meter_old"
  }, {
    path: "/old_account-settings/notifications",
    component: _1769f230,
    name: "old_account-settings-notifications"
  }, {
    path: "/old_account-settings/team",
    component: _2d0d160d,
    name: "old_account-settings-team"
  }, {
    path: "/old_account-settings/utility",
    component: _19d66128,
    name: "old_account-settings-utility"
  }, {
    path: "/old_challenges/create",
    component: _2a34d95f,
    name: "old_challenges-create"
  }, {
    path: "/signup/confirm",
    component: _dd0bcb06,
    name: "signup-confirm"
  }, {
    path: "/signup/confirm_email",
    component: _57001a4c,
    name: "signup-confirm_email"
  }, {
    path: "/signup/old_index",
    component: _d4949b92,
    name: "signup-old_index"
  }, {
    path: "/signup/send-verification",
    component: _6348b1bd,
    name: "signup-send-verification"
  }, {
    path: "/admind/organizations/create",
    component: _bd02cdbe,
    name: "admind-organizations-create"
  }, {
    path: "/admind/challenges/:challengeId",
    component: _d4e89d10,
    name: "admind-challenges-challengeId"
  }, {
    path: "/admind/pelm_energy_accounts/:energyAccountId",
    component: _c08b3c54,
    name: "admind-pelm_energy_accounts-energyAccountId"
  }, {
    path: "/admind/pelm_subscriptions/:subscriptionId",
    component: _5788632b,
    name: "admind-pelm_subscriptions-subscriptionId"
  }, {
    path: "/admind/property/:propertyId",
    component: _40b01e0b,
    name: "admind-property-propertyId"
  }, {
    path: "/admind/teams/:teamId",
    component: _58fe93b8,
    name: "admind-teams-teamId"
  }, {
    path: "/admind/users/:userId",
    component: _ef1ee810,
    name: "admind-users-userId"
  }, {
    path: "/admind/utility_providers/:utilId",
    component: _60d424f6,
    name: "admind-utility_providers-utilId"
  }, {
    path: "/challenges/rules/:rule?",
    component: _78fcac50,
    name: "challenges-rules-rule"
  }, {
    path: "/admind/organizations/:organizationId/details",
    component: _3af170ab,
    name: "admind-organizations-organizationId-details"
  }, {
    path: "/admind/organizations/:organizationId/update",
    component: _049f43f0,
    name: "admind-organizations-organizationId-update"
  }, {
    path: "/actions/:tipName",
    component: _3839540d,
    name: "actions-tipName"
  }, {
    path: "/challenges/:challenge",
    component: _3b5fc842,
    name: "challenges-challenge"
  }, {
    path: "/old_actions/:tipName",
    component: _12294125,
    name: "old_actions-tipName"
  }, {
    path: "/old_syncdata/:subscriptionId",
    component: _43d57c8e,
    name: "old_syncdata-subscriptionId"
  }, {
    path: "/syncdata/:subscriptionId",
    component: _21f7eca1,
    name: "syncdata-subscriptionId"
  }, {
    path: "/old_challenges/:challenge/:challenge_slug?",
    component: _d9fad944,
    name: "old_challenges-challenge-challenge_slug"
  }, {
    path: "/",
    component: _75eceb4e,
    name: "index"
  }, {
    path: "/confirm_email",
    component: _57001a4c
  }, {
    path: "/send-verification",
    component: _6348b1bd
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
